import Error from "@/core/services/Error";
import { mapState } from "vuex";
import { bus } from "@/main";
import { request } from "../../../core/services/Request";

const DEFAULT_FILTER_STATE = {
  created_from: null,
  created_to: null,
  event_from_date: null,
  event_to_date: null,
  halls: null,
  status: null,
  tags: null,
  event_type: null,
  sources: null,
  event_owner: null,
};


const FORM_STATE = {
  title: null,
  description: null,
  lead_id: null,
  tags: [],
  hall_id: undefined,
  event_type: null,
  event_from_date: null,
  event_to_date: null,
  no_of_guest: null,
  client_budget: null,
  status: null,
  user_id: undefined,
  _method: 'post',
};

export default {
  data() {
    return {
      operationTitle: 'Leads',
      formErrors: new Error({}),
      formFields: { ...FORM_STATE },
      filters: { ...DEFAULT_FILTER_STATE },
      dropdowns: {
        users: [],
        leads: [],
        halls: [],
        sources: [],
        eventType: [],
        tags: [
          {label: 'Event', id: 'Event'}
        ],
        dropAnalytics: [],
        subUsers: [],
      },
      selectedEvent: null,
      selectedBrochureEvent: null,
      pendingFollowUpCount: 0,
    };
  },
  methods: {
    async downloadReport() {

      console.log(this.filters)
      try {
        const response = await request({
          method: "post",
          url: 'events/export',
          data: {
            filters: this.filters
          }
        });

        if (response.data) {

          window.location.href = response.data.url
        }

      } catch (error) {
        this.dataSource = [];
        console.error(error)
      }
    },
    appendNewItem({ value, id }) {
      this.dropdowns.tags = [...this.dropdowns.tags, { id: value, label: value }];
    },
    async getPendingFollowUpCount() {
      try {
        const response = await request({
          url: 'events/follow-up/pending/count',
          method: 'get',
        });

        const { data } = response.data;

        this.$nextTick(function () {
          console.log({ data });
          this.pendingFollowUpCount = data;
        });


      } catch (error) {
        console.error(error);
      }
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      try {
        const lead_data = await this.$refs.lead_form.handleSubmitOperation();

        if (lead_data) {

          this.lead = lead_data

          this.formFields.lead_id = this.lead.id;

          const response = await request({
            url: this.formFields.id ? 'events/update' : 'events/create',
            method: 'post',
            data: this.formFields,
          });

          if ( this.formFields.id ) {
            this.$global.itemUpdated();
          } else {
            this.$global.itemAdded();
          }
          bus.$emit("check-selected-event");
          this.handleOperationClose();
        }
      } catch (error) {

        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    editEvent(id) {
      this.$router.push({ path: `events/edit/${ id }` });
    },
    async convertToQuotation(id, invoice = false) {
      try {
        const response = await request({
          method: 'post',
          url: `/events/convert/to/quotation`,
          data: {
            event_id: id,
            invoice: invoice
          }
        });
        const { data } = response.data;
        this.loadList(this.listQueryParams);

        if ( data ) {
          this.$router.push({ path: `${invoice ? 'invoice' : 'quotation'}/edit/${ data.id }` });
        }

      } catch (e) {
        console.error(e);
      }
    },
    async convertToBooking(id, status) {
      try {
        const response = await request({
          method: 'post',
          url: `/events/convert/to/booking`,
          data: {
            event_id: id,
            status: status
          }
        });
        const { data } = response.data;
        this.loadList(this.listQueryParams);

        if ( data ) {
          this.$router.push({ path: `bookings/edit/${ data.id }` });
        }

      } catch (e) {
        console.error(e);
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/events/detail/${ id }`,
        });
        const { data } = response.data;

        this.lead = { ...data.leads };

        this.formFields = {
          ...data,
          hall_id: data.halls.map((i) => i.id),
          user_id: data.users ? data.users.id : undefined,
          lead_id: data.leads ? data.leads.id : undefined
        };
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...FORM_STATE };
      }
    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/events/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
          localStorage.removeItem('event_id');
          this.$store.dispatch("getEventList");

        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async getLeads() {
      try {
        const response = await request({
          url: `/dropdowns/leads`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.leads = data.map((item) => {
          return {
            id: item.id,
            label: item.first_name + item.last_name + " (" + item.mobile_number + ")"
          };
        });
      } catch (e) {

      }
    },
    async getHalls() {
      try {
        const response = await request({
          url: `/dropdowns/halls`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.halls = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getTags() {
      try {
        const response = await request({
          url: `/dropdowns/tags/event`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.tags = data.map((item) => {
          return {
            id: item.name,
            label: item.name,
          };
        });
        this.dropdowns.tags.push({ id: 'Event', label: 'Event' });
      } catch (e) {

      }
    },
    async getSources() {
      try {
        const response = await request({
          url: `/dropdowns/sources`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.sources = data.map((item) => {
          return {
            id: item.id,
            label: item.source_name,
          };
        });
      } catch (e) {

      }
    },
    async getEventType() {
      try {
        const response = await request({
          url: '/dropdowns/event/type',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.eventType = data.map((item) => {
          return {
            id: item,
            label: item,
          };
        });
      } catch (e) {

      }
    },
    async getLostReasons() {
      try {
        const response = await request({
          url: '/dropdowns/event/lost-reasons',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.dropAnalytics = data.map((item) => {
          return {
            value: item,
            text: item,
          };
        });
      } catch (e) {

      }
    },
    async getSubUser() {
      try {
        const response = await request({
          url: `/dropdowns/sub/user`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.subUsers = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },
    handleFollowUpDetail(eventId) {
      this.selectedEvent = null;
      this.selectedEvent = eventId;
      this.$bvModal.show('follow-up');
    },
    async handleCloseFollowup(eventId) {
      try {
        const response = await request({
          url: `events/follow-up/close/${ eventId }`,
          method: 'post'
        });
        this.$global.notify('Followup Closed');
        this.loadList(this.listQueryParams);
        this.getPendingFollowUpCount();
      } catch
        (error) {
        this.$global.handleServerError(error);
      }
    },
    handleBrochureDetail(eventId) {
      this.selectedBrochureEvent = null;
      this.selectedBrochureEvent = eventId;
      this.$bvModal.show('send-brochure');
    },
    handleResetFilterClick() {
      this.filters = { ...DEFAULT_FILTER_STATE };
      this.isFilterApplied = 'reset';
      this.checkFilterCountStatus();
    },
    handleCloseOperation() {
      this.formFields = { ...FORM_STATE };
    },
    dateDisabled(val) {
      var myCurrentDate = new Date();
      var myPastDate = new Date(myCurrentDate);
      myPastDate.setDate(myPastDate.getDate() + 1);

      return ( myPastDate < new Date(val) );
    },
    getStatusTag(status) {
      const status_obj = {
        hot: 'label-light-danger',
        standard: 'label-light-warning',
        qualified: 'label-light-primary',
        won: 'label-light-success',
        lost: 'label-light-dark',
      };

      return status ? status_obj[status.toLowerCase()] : "";
    },
    handleShowFollowup() {
      this.show_follow_up = !this.show_follow_up;
      this.listQueryParams.show_follow_up = this.show_follow_up;
      this.search = '';
      this.listQueryParams.search = '';
      this.filters = { ...DEFAULT_FILTER_STATE };
      this.isFilterApplied = 'reset';
      this.checkFilterCountStatus();
    },
    async handleBounceEvent(item) {
      let bounceEvent = await this.$root.$confirm('Bounce Event Confirmation', "Are you sure you want to Bounce this event ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( bounceEvent ) {
        try {
          const response = await request({
            url: 'events/bounce/create',
            method: 'post',
            data: {
              ...item,
              event_id: item.id,
              event_status: item.status,
              lead_id: item.leads ? item.leads.id : null,
            },
          });
          this.$global.itemAdded('Bounce Event');
          this.loadList(this.listQueryParams);
        } catch
          (error) {
          this.$global.handleServerError(error);
        }
      }
    },
    async handleDeselectHall() {
      if ( this.formFields.id ) {
        try {
          const response = await request({
            url: 'halls/check',
            method: 'post',
            data: {
              id: this.formFields.hall_id,
              event_id: this.formFields.id
            },
          });

          const { data } = response.data;

          if ( data == 'y' ) {
            this.formErrors = new Error({ hall_id: ["hall already exists in quotation you can not removed it'"] });
            this.handleEditOperation(this.formFields.id);
          }

        } catch (e) {

        }
      }
    },
    selectLead(lead) {
      this.lead = lead;
    },
    handleOperationClose() {
      this.formFields = { ...FORM_STATE };
      this.$router.push({ name: "events" });
    },

  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
