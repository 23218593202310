<template>
  <div class="col-md-12">
    <KTCard>
      <template v-slot:title>
        {{
          $route.params.id ? 'Edit Lead' : 'Add Lead'
        }}
      </template>
      <template v-slot:toolbar>
        <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                  size="sm"
                  variant="primary"
                  :disabled="global.pendingRequests > 0"
        >
          <i v-show="global.pendingRequests > 0"
             class="fa fa-spin fa-spinner"></i>
          <i class="fa fa-save fa-sm"></i>
          Save
        </b-button>
        <b-button variant="danger" size="sm" class="mt-3"
                  @click="handleOperationClose()">
          <i class="fa fa-arrow-left fa-sm"></i> Cancel
        </b-button>
      </template>
      <template v-slot:body>
        <v-card>
          <div class="events-operation">
            <b-container fluid>
              <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                <LeadForm ref="lead_form" @select:lead="selectLead" :lead.sync="lead"/>
                <b-row>
                  <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group
                          :invalid-feedback="formErrors.first('title')"
                          label="Session *"
                          label-for="title"
                        >
                          <b-form-input
                            :state="((formErrors.has('title') ? false : null))"
                            @focus="$event.target.select()"
                            id="title"
                            type="text"
                            v-model="formFields.title"
                          ></b-form-input>
                        </b-form-group>
                      </b-col><!--/b-col-->
                      <b-col sm="4">
                        <b-form-group
                          :invalid-feedback="formErrors.first('hall_id')"
                          label="Select Hall *"
                          label-for="hall_id"
                        >
                          <treeselect
                            :class="[{'invalid is-invalid': (formErrors.has('hall_id'))}]"
                            :multiple="true"
                            :options="dropdowns.halls"
                            @input="handleDeselectHall"
                            id="Hall"
                            label="hall_id"
                            v-model="formFields.hall_id"
                          />
                        </b-form-group>
                      </b-col><!--/b-col-->
                      <b-col sm="4">
                        <b-form-group
                          :invalid-feedback="formErrors.first('event_type')"
                          label="Event Type *"
                          label-for="event_type"
                        >
                          <treeselect
                            :class="[{'invalid is-invalid': (formErrors.has('event_type'))}]"
                            :options=" dropdowns.eventType"
                            v-model="formFields.event_type"
                          />
                        </b-form-group>
                      </b-col><!--/b-col-->
                    </b-row><!--/b-row-->
                    <b-row>

                      <b-col sm="4">
                        <b-form-group
                          :invalid-feedback="formErrors.first('event_from_date')"
                          label="Event From Date *"
                          label-for="event_from_date"
                        >
                          <b-datepicker
                            :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                            :state="((formErrors.has('event_from_date') ? false : null))"
                            id="event_from_date"
                            type="text"
                            v-model="formFields.event_from_date"
                          />
                        </b-form-group>
                      </b-col><!--/b-col-->
                      <b-col sm="4">
                        <b-form-group
                          :invalid-feedback="formErrors.first('event_to_date')"
                          label="Event To Date *"
                          label-for="event_to_date"
                        >
                          <b-datepicker
                            :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                            :state="((formErrors.has('event_to_date') ? false : null))"
                            id="event_to_date"
                            type="text"
                            v-model="formFields.event_to_date"
                          />
                        </b-form-group>
                      </b-col><!--/b-col-->
                      <b-col sm="4">
                        <b-form-group
                          :invalid-feedback="formErrors.first('no_of_guest')"
                          label="No Of Guest"
                          label-for="no_of_guest"
                        >
                          <b-form-input
                            :state="((formErrors.has('no_of_guest') ? false : null))"
                            @focus="$event.target.select()"
                            id="no_of_guest"
                            type="text"
                            v-model="formFields.no_of_guest"
                            v-numericOnly.keyup
                          ></b-form-input>
                        </b-form-group>
                      </b-col><!--/b-col-->
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <b-form-group
                          :invalid-feedback="formErrors.first('client_budget')"
                          label="Client Budget"
                          label-for="client_budget"
                        >
                          <b-form-input
                            :state="((formErrors.has('client_budget') ? false : null))"
                            @focus="$event.target.select()"
                            id="client_budget"
                            step="0.1"
                            v-model="formFields.client_budget"
                            v-numericOnly.keyup
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group
                          :invalid-feedback="formErrors.first('status')"
                          label="Status *"
                          label-for="status"
                        >
                          <b-form-select
                            v-if="!['Won', 'Tentative'].includes(formFields.status)"
                            :options="[{value:'Standard',text:'Standard'},{value:'Hot',text:'Hot'},{value:'Qualified',text:'Qualified'},{value:'Lost',text:'Lost'}]"
                            :state="((formErrors.has('status') ? false : null))"
                            v-model="formFields.status"
                          >
                          </b-form-select>
                          <span v-else>
                            {{formFields.status}}
                          </span>
                        </b-form-group>
                      </b-col><!--/b-col-->
                      <b-col sm="4" v-if="!$global.parentUserExist()">
                        <b-form-group
                          :invalid-feedback="formErrors.first('user_id')"
                          label="Event Owner"
                          label-for="user_id"
                          v-if="!formFields.check_event_owner"

                        >
                          <treeselect
                            :options=" dropdowns.subUsers"
                            :state="((formErrors.has('user_id') ? false : null))"
                            v-model="formFields.user_id"
                          >
                          </treeselect>
                        </b-form-group>
                      </b-col><!--/b-col-->
                    </b-row>
                    <b-row>
                      <b-col sm="6">
                        <b-form-group
                          :invalid-feedback="formErrors.first('description')"
                          label="Description"
                          label-for="description"
                        >
                          <b-form-input
                            :state="((formErrors.has('description') ? false : null))"
                            @focus="$event.target.select()"
                            id="description"
                            type="text"
                            v-model="formFields.description"
                          ></b-form-input>
                        </b-form-group>
                      </b-col><!--/b-col-->
                      <b-col sm="6">
                        <b-form-group
                          :invalid-feedback="formErrors.first('tags')"
                          label="Tags"
                          label-for="tags"
                        >
                          <treeselect
                            :class="[{'invalid is-invalid': (formErrors.has('tags'))}]"
                            :multiple="true"
                            :options="dropdowns.tags"
                            id="tags"
                            label="Tags"
                            v-model="formFields.tags"
                            @new-node="appendNewItem"
                            :create-tag="true"
                          />
                        </b-form-group>
                      </b-col><!--/b-col-->
                    </b-row>
                    <b-row v-show="formFields.status == 'Lost'">
                      <b-col sm="4">
                        <b-form-group
                          :invalid-feedback="formErrors.first('drop_analytics')"
                          :label="`Select Lost Reason *`"
                          label-for="status"
                        >
                          <b-form-select
                            :options="dropdowns.dropAnalytics"
                            :state="((formErrors.has('drop_analytics') ? false : null))"
                            v-model="formFields.drop_analytics"
                          >
                          </b-form-select>
                        </b-form-group>
                      </b-col><!--/b-col-->
                    </b-row>
                    <hr/>
                    <b-row class="operation-footer">
                      <b-col sm="12">
                        <b-button
                          :disabled="global.pendingRequests > 0"
                          size="sm"
                          type="submit"
                          variant="primary"
                        >
                          <i class="fa fa-spin fa-spinner"
                             v-show="global.pendingRequests > 0"></i>
                          <i class="fa fa-save fa-sm"></i>
                          Save
                        </b-button>
                        <b-button @click="handleOperationClose()" class="ml-3"
                                  size="sm"
                                  variant="danger">
                          <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col><!--/b-col-->
                </b-row><!--/b-row-->
              </form><!--/form-->
            </b-container><!--/b-container-->
          </div><!--/.events-operation-->
        </v-card>
      </template>
    </KTCard>
  </div>
</template>

<script>
import LeadForm from '../leads/form'
import ModuleMixin from "./module.mixin"
import KTCard from "@/view/content/Card.vue";

export default {
  mixins: [ModuleMixin],
  components: {
    LeadForm,
    KTCard,
  },
  data() {
    return {
      eventConfirmData: null,
      lead: {},
      eventDetail: null,
    }
  },
  computed: {
    operation() {
      return this.$route.params.id ? 'edit' : 'add'
    }
  },
  mounted() {
    // this.getLeads()
    // this.getSources()
    this.getHalls()
    this.getEventType()
    this.getSubUser()
    this.getTags()
    this.getLostReasons()

    this.order = 'hall'
    this.lead = {}

    if (this.$route.params && this.$route.params.id) {
      this.handleEditOperation(this.$route.params.id)
    }
  },
}
</script>
