<template>
  <b-row>
    <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
      <b-row>
        <b-col sm="4">
          <b-form-group
            label="Mobile Number *"
            label-for="mobile_number"
          >
            <b-form-input
              id="first_name"
              v-model="formFields.mobile_number"
              type="text"
              :state="((hasError('mobile_number') ? false : null))"
              @focus="$event.target.select()"
            ></b-form-input>
          </b-form-group>
        </b-col><!--/b-col-->
        <b-col sm="4">
          <b-form-group
            label="First Name *"
            label-for="first_name"
            :invalid-feedback="getError('first_name')"
          >
            <b-form-input
              id="first_name"
              v-model="formFields.first_name"
              type="text"
              :state="((hasError('first_name') ? false : null))"
              @focus="$event.target.select()"
            ></b-form-input>
          </b-form-group>
        </b-col><!--/b-col-->
        <b-col sm="4">
          <b-form-group
            label="Last Name *"
            label-for="last_name"
            :invalid-feedback="getError('last_name')"
          >
            <b-form-input
              id="last_name"
              v-model="formFields.last_name"
              type="text"
              :state="((hasError('last_name') ? false : null))"
              @focus="$event.target.select()"
            ></b-form-input>
          </b-form-group>
        </b-col><!--/b-col-->
      </b-row><!--/b-row-->
      <b-row>
        <b-col sm="4">
          <b-form-group
            label="Email"
            label-for="email"
            :invalid-feedback="getError('email')"
          >
            <b-form-input
              id="email"
              v-model="formFields.email"
              type="email"
              :state="((hasError('email') ? false : null))"
              @focus="$event.target.select()"
            ></b-form-input>
          </b-form-group>
        </b-col><!--/b-col-->
        <b-col sm="4">
          <b-form-group
            label="Alternate Mobile Number"
            label-for="alternate_mobile_number"
            :invalid-feedback="getError('alternate_mobile_number')"
          >
            <b-form-input
              id="alternate_mobile_number" v-numericOnly.keyup
              v-model="formFields.alternate_mobile_number"
              type="text"
              :state="((hasError('alternate_mobile_number') ? false : null))"
              @focus="$event.target.select()"
            ></b-form-input>
          </b-form-group>
        </b-col><!--/b-col-->
        <b-col sm="4">
          <b-form-group
            label="Source"
            label-for="asset_id"
            :invalid-feedback="getError('asset_id')"
          >
            <b-form-select
              v-model="formFields.asset_id"
              :options="dropdowns.sources"
              :state="((hasError('asset_id') ? false : null))"
            >
            </b-form-select>
          </b-form-group>
        </b-col><!--/b-col-->
      </b-row>
      <b-row>
        <!--                <b-col sm="4">
                            <b-form-group
                                label="Country"
                                label-for="country_id"
                                :invalid-feedback="getError('country_id')"
                            >
                                <b-form-select
                                    v-model="formFields.country_id"
                                    :options="dropdowns.countries"
                                    :state="((hasError('country_id') ? false : null))"
                                >
                                </b-form-select>
                            </b-form-group>
                        </b-col>&lt;!&ndash;/b-col&ndash;&gt;
                        <b-col sm="4">
                            <b-form-group
                                label="States"
                                label-for="state_id"
                                :invalid-feedback="getError('state_id')"
                            >
                                <b-form-select
                                    v-model="formFields.state_id"
                                    :options="_.filter(dropdowns.states,(i) => i.country_id === formFields.country_id)"
                                    :state="((hasError('state_id') ? false : null))"
                                >
                                </b-form-select>
                            </b-form-group>
                        </b-col>&lt;!&ndash;/b-col&ndash;&gt;-->
        <b-col sm="4">
          <b-form-group
            label="Company"
            label-for="company"
            :invalid-feedback="getError('company')"
          >
            <b-form-input
              id="company"
              v-model="formFields.company"
              type="text"
              :state="((hasError('company') ? false : null))"
              @focus="$event.target.select()"
            ></b-form-input>
          </b-form-group>
        </b-col><b-col sm="4">
        <b-form-group
          label="GST No"
          label-for="gst_no"
          :invalid-feedback="getError('gst_no')"
        >
          <b-form-input
            id="gst_no"
            v-model="formFields.gst_no"
            type="text"
            :state="((hasError('gst_no') ? false : null))"
            @focus="$event.target.select()"
          ></b-form-input>
        </b-form-group>
      </b-col>
        <b-col sm="4">
          <b-form-group
            label="Address"
            label-for="address"
            :invalid-feedback="getError('address')"
          >
            <b-form-input
              id="address"
              v-model="formFields.address"
              type="text"
              :state="((hasError('address') ? false : null))"
              @focus="$event.target.select()"
            ></b-form-input>
          </b-form-group>
        </b-col><!--/b-col-->
      </b-row>
      <b-row>

      </b-row>
      <hr/>
    </b-col><!--/b-col-->
  </b-row><!--/b-row-->
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {request} from "@/core/services/Request";
import {getPropertyId, getAuthUser} from "@/util/globalFunction";
import handleError from "@/util/handleError";

const FORM_STATE = {
  first_name: null,
  last_name: null,
  email: null,
  mobile_label: null,
  mobile_number: null,
  alternate_mobile_number: null,
  sources: undefined,
  country_id: 101,
  company: undefined,
  gst_no: undefined,
  state_id: undefined,
  asset_id: undefined,
  user_id: undefined,
  _method: 'post',
  status: false,
};

export default {
  name: "LeadForm",
  props: ["lead"],
  mixins: [handleError],
  data() {
    return {
      formFields: {...FORM_STATE},
      options: [],
      dropdowns: {
        sources: [],
        states: [],
        countries: [],
        status: [
          {value: 'Hot', text: 'Hot'},
          {value: 'Standard', text: 'Standard'},
          {value: 'Qualified', text: 'Qualified'},
          {value: 'Won', text: 'Won'},
          {value: 'Lost', text: 'Lost'},
        ]
      },
      status: false,
    };
  },
  async mounted() {
    /*if (this.$route.query && this.$route.query.operation && this.$route.query.id) {
        this.handleEditOperation(this.$route.query.id)
    }*/
    if (this.lead) {
      this.formFields = this.lead;
    } else {
      this.formFields = {...FORM_STATE};
    }

    await this.getSources();
    // this.getStates()
    // this.getCountries()
    /*if (this.operation == "add") {
        if (localStorage.getItem("lead-create")) {
            let data = JSON.parse(localStorage.getItem("lead-create"))
            let name = data.name.split(" ");
            this.formFields.mobile_number = data.phone
            this.formFields.email = data.email
            this.formFields.first_name = name[0]
            this.formFields.last_name = name[1]
        }
    }*/
  },
  methods: {
    async handleSelectMobileUserDetail(id) {
      try {
        const response = await request({
          url: `/dropdowns/user/${id}`,
          method: 'post',
        });

        const {data} = response.data;
        if (data) {
          let name = data.name.split(" ");
          this.formFields.email = data.email;
          this.formFields.first_name = name[0];
          this.formFields.last_name = name[1];
        }
      } catch (e) {

      }
    },
    async addLead(val) {/*
            this.formFields = {...FORM_STATE}

            this.options.push({mobile_label: newTag, mobile_number: newTag})

            this.formFields.mobile_number = newTag
            this.formFields.mobile_label = newTag*/

      this.$nextTick(function () {
        if (val.id) {
          this.$emit('select:lead', val);
        } else if (val.mobile_label) {
          this.formFields.mobile_number = val.mobile_label;

        } else {
          this.formFields.mobile_number = val
        }
      });
    },
    async searchLead(search) {
      try {
        if (search.length >= 10) {

          const response = await request({
            url: `/dropdowns/leads`,
            method: 'post',
            data: {search}
          });

          const {data} = response.data;

          this.options = data;
        } else {
          this.options = [];
        }
      } catch (e) {
        console.error(e);
      }
    },
    afterCloseOperation() {
      this.formFields = {...FORM_STATE};
    },
    async handleSubmitOperation() {
      try {
        const response = await request({
          url: this.formFields.id ? 'leads/update' : 'leads/create',
          method: 'post',
          data: this.formFields,
        });

        const {data} = response.data;

        return data;
      } catch (error) {
        if (error.request && error.status === 422) {
          this.errors = error.data.errors;
        } else {
          this.errors = {};
        }
        this.$global.handleServerError(error);
        return false;
      }

    },
    async handleDeleteOperation(id) {
      let response = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if (response) {
        try {
          const response = await request({
            method: 'post',
            url: '/leads/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    /*async handleEditOperation(id) {
        try {
            const response = await request({
                method: 'get',
                url: `/leads/detail/${id}`,
            })
            const {data} = response.data
            this.formFields = {
                ...data,
                asset_id: data.sources ? data.sources.id : undefined,
                property_id: data.property ? data.property.id : undefined,
            }
        } catch (e) {
            this.$global.itemEditFails()
            this.formFields = {...FORM_STATE}
        }
    },*/
    async getSources() {
      try {
        const response = await request({
          url: '/dropdowns/sources',
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.sources = data.map((item) => {
          return {
            value: item.id,
            text: item.source_name,
          };
        });
      } catch (e) {

      }
    },
    async getStates() {
      try {
        const response = await request({
          url: '/dropdowns/states',
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.states = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
            country_id: item.country_id
          };
        });
      } catch (e) {

      }
    },
    async getCountries() {
      try {
        const response = await request({
          url: '/dropdowns/countries',
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.countries = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      } catch (e) {

      }
    },
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },

  watch: {
    lead: function (nVal, oVal) {
      if (nVal) {
        this.formFields = nVal;

        if (nVal?.sources?.id) {
          this.formFields.asset_id = nVal?.sources?.id;
        }
      }
    }
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
